<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Account Report</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Profit and Loss Account
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">
              Profit and Loss Account
            </h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 d-flex justify-content-end pr-2 pb-2">
        <div class="bg-theam-secondary d-flex m-2 p-1">
          <div class="report-tool-icon mr-3 ml-1">
            <i class="fas fa-print" title="Print" @click="print"></i>
          </div>
          <!-- <div class="report-tool-icon mr-1">
            <i class="fas fa-download" title="Download"></i>
          </div> -->
        </div>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <!-- <div class="bg-theam-secondary table-search-head">
      <div class="row">
        <div class="filter-input col-md-10 d-flex mr-0 pr-0">
          <select
            class="ml-2"
            v-model="filterParams.fiscal_year"
            @change="getFilteredDataWithoutDates"
          >
            <option
              v-for="(year, index) in sessionYears"
              :key="index"
              :value="year"
            >
              {{ year }}
            </option>
          </select>
          <select
            v-model="filterParams.branch"
            class="filter-input ml-2"
            @change="selectBranch"
          >
            <option value>All Branch</option>
            <option
              v-for="(branch, index) in branches"
              :key="index"
              :value="branch.id"
            >
              {{ branch.name }}
            </option>
          </select>

          <SearchDayMonthComponent
            @clicked="monthDayClicked"
          ></SearchDayMonthComponent>
          <div class="ml-2">
            <label class="filter-lavel mb-0 mr-1">From</label>
            <input
              type="date"
              v-model="filterParams.from_date"
              placeholder="From"
              class="filter-input"
              @change="selectFromDate"
            />
            <label class="filter-lavel mb-0 ml-1">To</label>
            <input
              type="date"
              v-model="filterParams.to_date"
              placeholder="To"
              class="ml-1 filter-input"
              @change="selectEndDate"
            />
          </div>
        </div>
      </div>
    </div> -->
    <div class="bg-theam-secondary">
      <div class="row mx-0">
        <div class="col-6 m-0 p-0">
          <table class="table table2 border-pl-right">
            <thead>
              <tr>
                <th class="wd-85p table-start-item">Particular</th>
                <th class="wd-15p text-right table-end-item">Dr. Amount</th>
              </tr>
            </thead>
          </table>
        </div>
        <div class="col-6 m-0 p-0">
          <table class="table table2">
            <thead>
              <tr>
                <th class="wd-85p table-start-item">Particular</th>
                <th class="wd-15p text-right table-end-item">Cr. Amount</th>
              </tr>
            </thead>
          </table>
        </div>
      </div>

      <div class="all-records" v-if="!loading && !error">
        <div class="row mx-0">
          <div class="col-6 m-0 p-0 border-pl-right">
            <table class="table table4">
              <thead>
                <tr>
                  <th class="wd-85p table-start-item">
                    {{ profitLossData.opening_stock.title }}
                  </th>
                  <th class="wd-15p text-right table-end-item">
                    {{
                      parseDigitForSlip(profitLossData.opening_stock.total_blnc)
                    }}
                  </th>
                </tr>
              </thead>
            </table>
            <table class="table table4">
              <thead>
                <tr>
                  <th class="wd-85p table-start-item">
                    {{ profitLossData.purchase.title }}
                  </th>
                  <th class="wd-15p text-right table-end-item">
                    {{ parseDigitForSlip(profitLossData.purchase.total_blnc) }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="table-start-item pl-4">
                    {{ profitLossData.purchase.accounts[0].title }}
                  </td>
                  <td class="text-right table-end-item">
                    {{
                      parseDigitForSlip(
                        profitLossData.purchase.accounts[0].total_blnc
                      )
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="table-start-item pl-4">
                    {{ profitLossData.purchase.accounts[1].title }}
                  </td>
                  <td class="text-right table-end-item">
                    {{
                      parseDigitForSlip(
                        profitLossData.purchase.accounts[1].total_blnc
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table table4">
              <thead>
                <tr>
                  <th class="wd-85p table-start-item">
                    {{ profitLossData.direct_expense.title }}
                  </th>
                  <th class="wd-15p text-right table-end-item">
                    {{
                      parseDigitForSlip(
                        profitLossData.direct_expense.total_blnc
                      )
                    }}
                  </th>
                </tr>
              </thead>
              <tbody v-if="profitLossData.direct_expense.accounts">
                <tr
                  v-for="expAccount in profitLossData.direct_expense.accounts"
                  :key="expAccount.slug"
                >
                  <td class="table-start-item pl-4">
                    {{ expAccount.account_name }}
                  </td>
                  <td class="text-right table-end-item">
                    {{ parseDigitForSlip(expAccount.closing_balance) }}
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              class="table table4"
              v-if="profitLossData.gross_profit.total_blnc > 0"
            >
              <thead>
                <tr>
                  <th class="wd-85p table-start-item">
                    {{ profitLossData.gross_profit.title }}
                  </th>
                  <th class="wd-15p text-right table-end-item">
                    {{
                      parseDigitForSlip(profitLossData.gross_profit.total_blnc)
                    }}
                  </th>
                </tr>
              </thead>
            </table>
          </div>
          <div class="col-6 m-0 p-0">
            <table class="table table4">
              <thead>
                <tr>
                  <th class="wd-85p table-start-item">
                    {{ profitLossData.sales.title }}
                  </th>
                  <th class="wd-15p text-right table-end-item">
                    {{ parseDigitForSlip(profitLossData.sales.total_blnc) }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="table-start-item pl-4">
                    {{ profitLossData.sales.accounts[0].title }}
                  </td>
                  <td class="text-right table-end-item">
                    {{
                      parseDigitForSlip(
                        profitLossData.sales.accounts[0].total_blnc
                      )
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="table-start-item pl-4">
                    {{ profitLossData.sales.accounts[1].title }}
                  </td>
                  <td class="text-right table-end-item">
                    {{
                      parseDigitForSlip(
                        profitLossData.sales.accounts[1].total_blnc
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table table4">
              <thead>
                <tr>
                  <th class="wd-85p table-start-item">
                    {{ profitLossData.direct_income.title }}
                  </th>
                  <th class="wd-15p text-right table-end-item">
                    {{
                      parseDigitForSlip(profitLossData.direct_income.total_blnc)
                    }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="incAccount in profitLossData.direct_income.accounts"
                  :key="incAccount.slug"
                >
                  <td class="table-start-item pl-4">
                    {{ incAccount.account_name }}
                  </td>
                  <td class="text-right table-end-item">
                    {{ parseDigitForSlip(incAccount.closing_balance) }}
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table table4">
              <thead>
                <tr>
                  <th class="wd-85p table-start-item">
                    {{ profitLossData.closing_stock.title }}
                  </th>
                  <th class="wd-15p text-right table-end-item">
                    {{
                      parseDigitForSlip(profitLossData.closing_stock.total_blnc)
                    }}
                  </th>
                </tr>
              </thead>
            </table>
            <table
              class="table table4"
              v-if="profitLossData.gross_loss.total_blnc > 0"
            >
              <thead>
                <tr>
                  <th class="wd-85p table-start-item">
                    {{ profitLossData.gross_loss.title }}
                  </th>
                  <th class="wd-15p text-right table-end-item">
                    {{
                      parseDigitForSlip(profitLossData.gross_loss.total_blnc)
                    }}
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col-6 m-0 p-0 border-pl-right">
            <table class="table table4">
              <thead>
                <tr class="bg-primary text-white">
                  <th class="wd-85p table-start-item">Balance</th>
                  <th class="wd-15p text-right table-end-item">
                    {{ parseDigitForSlip(profitLossData.dr_balance) }}
                  </th>
                </tr>
              </thead>
            </table>
          </div>
          <div class="col-6 m-0 p-0">
            <table class="table table4">
              <thead>
                <tr class="bg-primary text-white">
                  <th class="wd-85p table-start-item">Balance</th>
                  <th class="wd-15p text-right table-end-item">
                    {{ parseDigitForSlip(profitLossData.cr_balance) }}
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col-6 m-0 p-0 border-pl-right">
            <table class="table table4">
              <thead>
                <tr>
                  <th class="wd-85p table-start-item">Indirect Expense</th>
                  <th class="wd-15p text-right table-end-item">
                    {{
                      parseDigitForSlip(
                        profitLossData.indirect_expense.total_blnc
                      )
                    }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="indExpense in profitLossData.indirect_expense.accounts"
                  :key="indExpense.slug"
                >
                  <td class="table-start-item pl-4">
                    {{ indExpense.account_name }}
                  </td>
                  <td class="text-right table-end-item">
                    {{ parseDigitForSlip(indExpense.closing_balance) }}
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              class="table table4"
              v-if="profitLossData.net_profit.total_blnc > 0"
            >
              <thead>
                <tr>
                  <th class="wd-85p table-start-item">Net Profit</th>
                  <th class="wd-15p text-right table-end-item">
                    {{
                      parseDigitForSlip(profitLossData.net_profit.total_blnc)
                    }}
                  </th>
                </tr>
              </thead>
            </table>
          </div>
          <div class="col-6 m-0 p-0">
            <table class="table table4">
              <thead>
                <tr>
                  <th class="wd-85p table-start-item">Indirect Income</th>
                  <th class="wd-15p text-right table-end-item">
                    {{
                      parseDigitForSlip(
                        profitLossData.indirect_income.total_blnc
                      )
                    }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="inDirectIncomeAcc in profitLossData.indirect_income
                    .accounts"
                  :key="inDirectIncomeAcc.slug"
                >
                  <td class="table-start-item pl-4">
                    {{ inDirectIncomeAcc.account_name }}
                  </td>
                  <td class="text-right table-end-item">
                    {{ parseDigitForSlip(inDirectIncomeAcc.closing_balance) }}
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              class="table table4"
              v-if="profitLossData.net_loss.total_blnc > 0"
            >
              <thead>
                <tr>
                  <th class="wd-85p table-start-item">Net Loss</th>
                  <th class="wd-15p text-right table-end-item">
                    {{ parseDigitForSlip(profitLossData.net_loss.total_blnc) }}
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col-6 m-0 p-0">
            <table class="table table2 border-pl-right">
              <thead>
                <tr>
                  <th class="wd-85p table-start-item">Total</th>
                  <th class="wd-15p text-right table-end-item">
                    {{ parseDigitForSlip(profitLossData.dr_total) }}
                  </th>
                </tr>
              </thead>
            </table>
          </div>
          <div class="col-6 m-0 p-0">
            <table class="table table2">
              <thead>
                <tr>
                  <th class="wd-85p table-start-item">Total</th>
                  <th class="wd-15p text-right table-end-item">
                    {{ parseDigitForSlip(profitLossData.cr_total) }}
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <div v-else-if="error" class="tx-center">{{ errorMessage }}</div>
      <div v-else-if="profitLossData.length == 0" class="tx-center">
        No records found.
      </div>
      <div v-else class="tx-center">Loading...</div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
// import SearchDayMonthComponent from "../../../../shared/SearchDayMonthComponent";
import Services from "./Services/Services";

export default {
  components: {
    // SearchDayMonthComponent,
  },
  data() {
    return {
      sessionYears: ["2021-22"],
      branches: [],
      filterParams: {
        pageIndex: "all",
        branch: "",
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        from_date: "",
        to_date: "",
        offset: 0,
        fiscal_year: "2021-22",
      },
      errorMessage: "",
      loading: false,
      error: false,
      pageReload: "",
    };
  },
  computed: {
    ...mapGetters("profitLoss", ["profitLossData"]),
  },
  beforeMount() {
    this.getFilteredDataWithoutDates();
    this.pageReload = true;
    Services.getUserAccessBranches()
      .then((response) => {
        this.branches = response.data.data;
        // this.sessionYears = response.data.data.fiscalYears;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    //print page
    print() {
      window.print();
    },
    //month day component clicked
    monthDayClicked(value) {
      if (value) {
        this.filterParams.from_date = "";
        this.filterParams.to_date = "";
        this.filterParams.day = value.day;
        this.filterParams.month = value.month;
        this.filterParams.year = value.year;
      }
      //make api call
      this.page = 1;
      this.pageSerialNo = 1;
      this.getFilteredData();
    },

    selectBranch() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.getFilteredDataWithoutDates();
    },
    selectFromDate() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.getFilteredDataWithoutDates();
    },
    selectEndDate() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.getFilteredDataWithoutDates();
    },
    // today/month section
    getFilteredData() {
      this.loading = true;
      let params = {
        pageIndex: this.filterParams.pageIndex,
        branch: this.filterParams.branch,
        today: this.filterParams.day,
        month: this.filterParams.month + 1,
        year: this.filterParams.year,
        from_date: this.filterParams.from_date,
        to_date: this.filterParams.to_date,
        page_reload: this.pageReload,
        fiscal_year: this.filterParams.fiscal_year,
      };
      this.ajaxRequest(params);
    },
    // without today/month section
    getFilteredDataWithoutDates() {
      this.loading = true;
      let params = {
        pageIndex: this.filterParams.pageIndex,
        branch: this.filterParams.branch,
        today: "",
        month: "",
        year: this.filterParams.year,
        from_date: this.filterParams.from_date,
        to_date: this.filterParams.to_date,
        page_reload: this.pageReload,
        fiscal_year: this.filterParams.fiscal_year,
      };
      this.ajaxRequest(params);
    },

    ajaxRequest(params) {
      this.error = false;
      Services.getProfitAndLossReportFilterDatas(params)
        .then((res) => {
          this.$store.commit("profitLoss/setProfitLossData", res.data.data);

          this.totalPageCount = res.data.data.total_no_of_pages;
          this.loading = false;
        })
        .catch((error) => {
          this.error = true;
          console.log(error);
          if (
            !Object.prototype.hasOwnProperty.call(
              error,
              "response.data.error_message"
            )
          ) {
            if (error.response.data.error_message == "Access Denied.") {
              this.errorMessage = "Access Denied !!!";
            } else {
              this.errorMessage = "Error fetching data from the server.";
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style>
</style>